import { createTheme } from '@mui/material/styles';
import Palette from './palette';
import useConfig from 'hooks/useConfig';

// const { navType } = useConfig();
const navType = 'light';

// Create the theme using navType and presetColor
const themeOptions = {
  palette: Palette(navType)
};

const themes = createTheme(themeOptions);
export default themes;

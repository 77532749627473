// third-party
import { combineReducers } from 'redux';

// project imports
import calendarReducer from './slices/calendar';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  calendar: calendarReducer,
});

export default reducer;

import { lazy } from 'react';

// project imports
import MainLayout from 'MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

import GuestGuard from 'utils/route-guard/GuestGuard';
import AdminGuard from 'utils/route-guard/AdminGuard';
import EmployeeTable from "EmployeeTable";
import Employee from "Employee";
import Calendar from "views/calendar/index";
import StopwatchForm from "StopwatchForm";
import Download from 'download';
import Register from 'register';
import Login from 'Login';

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/home',
      element: (
        <AuthGuard>
          <StopwatchForm />
        </AuthGuard>
      ),
    },
    {
      path: '/employees',
      element: (
        <AuthGuard>
          <EmployeeTable />
        </AuthGuard>
      ),
    },
    {
      path: '/employee/:id',
      element: (
        <AuthGuard>
          <Employee />
        </AuthGuard>
      ),
    },
    {
      path: '/tasks',
      element: (
        <AuthGuard>
          <Calendar />
        </AuthGuard>
      ),
    },
    {
      path: '/download',
      element: <Download />,
    },
    {
      path: '/register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: '/login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
  ]
};
export default MainRoutes;
import React, { useState, useEffect } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { useLocale } from './contexts/LocaleContext';
import { useIntl } from 'react-intl'; // Import the useIntl hook
import useAuth from './hooks/useAuth';
import Header from './header';
import { useNavigate } from 'react-router-dom';

const EmployeeTable = () => {
  const intl = useIntl();
  const [employees, setEmployees] = useState([])
  const { getToken } = useAuth();
  const navigate = useNavigate();

  function localeFormat(id) {
    return intl.formatMessage({ id });
  }

  const handleEditClick = (row) => {
    navigate(`/employee/${row.id}`);
  };

  async function fetchEmployees() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/employees`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setEmployees(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchEmployees();
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'employee_name', headerName: localeFormat('Employee Name'), width: 200 },
    { field: 'personal_code', headerName: localeFormat('Personal Code'), width: 150 },
    { field: 'email', headerName: localeFormat('Email'), width: 200 },
    { field: 'uid', headerName: localeFormat('UID'), width: 150 },
    {
      field: 'edit',
      headerName: localeFormat('Edit'),
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem icon={<EditIcon />} onClick={() => handleEditClick(params.row)} label="Edit" />,
      ],
      width: 70,
    },
  ];

  return (
    <div>
      <div style={{ height: 'auto', width: '100%' }}>
        <DataGrid
          rows={employees}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25, 100]}
        />
      </div>
    </div>
  );
}

export default EmployeeTable;

import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { applyActionCode } from "firebase/auth";

// action - state management
import { LOGIN, LOGOUT } from '../store/actions';
import accountReducer from '../store/accountReducer';

// project imports
import Loader from '../ui/loader';
import { FIREBASE_API } from '../config';
import { encode as base64Encode } from 'base-64';

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  let callback = null;
  let metadataRef = null;

  const getToken = async () => {
    const user = firebase.auth().currentUser;

    if (!user) {
      return null;
    }

    const idToken = await user.getIdToken();
    return idToken;
  };

  const auth = firebase.auth();

  const checkIfVerificationSaved = async (email) => {
    const url = `${process.env.REACT_APP_URL}/check-verification/${email}`;
    const token = await getToken();

    const headers = new Headers();

    // headers.append('Content-Type', 'text/json');
    headers.append('Authorization', `Bearer ${token}`);

    const result = await fetch(url, {
      method: 'GET',
      headers
    });
    const json = await result.json();
    // console.log(json);

    return json;
  };

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        // console.log(user)
        // user.emailVerified = true
        // Remove previous listener.
        if (callback) {
          // metadataRef.off('value', callback);
        }
        if (user) {
          // Check if refresh is required.
          // metadataRef = firebase.database().ref(`metadata/${user.uid}/refreshTime`);
          callback = (snapshot) => {
            // Force refresh to pick up the latest custom claims changes.
            // Note this is always triggered on first call. Further optimization could be
            // added to avoid the initial trigger when the token is issued and already contains
            // the latest claims.
            user.getIdToken(true);
          };
          // Subscribe new listener to changes on that node.
          // metadataRef.on('value', callback);
          if (user.emailVerified) {
            //check if verification status is saved to database
            //if not, send tokens, and save the status
            // checkIfVerificationSaved(user.email)
          }

          const fetchData = async () => {
            // let role = await checkUser(user.email);
            // console.log(role)
            let role = 'user';

            firebase
              .auth()
              .currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                // Confirm the user is an Admin.
                if (idTokenResult.claims.admin) {
                  // Show admin UI.
                  user.role = 'admin';
                } else if (role === 'brand') {
                  user.role = 'brand'
                } else {
                  // Show regular user UI.
                  user.role = 'user';
                }
                dispatch({
                  type: LOGIN,
                  payload: {
                    isLoggedIn: true,
                    user: {
                      id: user.uid,
                      email: user.email,
                      name: user.displayName,
                      role: user.role,
                      emailVerified: user.emailVerified
                    }
                  }
                });
                // console.log(user.role);
              })
              .catch((error) => {
                console.log(error);
              });
          }

          // call the function
          fetchData()
            // make sure to catch any error
            .catch(console.error);

        } else {
          dispatch({
            type: LOGOUT
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => { };
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  const firebaseFacebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => { },
        firebaseGoogleSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile,
        getToken,
        auth
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

export default FirebaseContext;

// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  events: []
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.events = action.payload;
    },

    // ADD EVENT
    addEventSuccess(state, action) {
      state.events = action.payload;
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.events = action.payload;
    },

    // REMOVE EVENT
    removeEventSuccess(state, action) {
      state.events = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export async function getEvents(token) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/calendar/events', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      dispatch(slice.actions.getEventsSuccess(response.data.events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addEvent(event, token) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        '/api/calendar/events/new',
        event,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      dispatch(slice.actions.addEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateEvent(event, token) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/calendar/events/update', event, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      dispatch(slice.actions.updateEventSuccess(response.data.events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function removeEvent(eventId, token) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/calendar/events/remove', { eventId }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      dispatch(slice.actions.removeEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}